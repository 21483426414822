body {
  font-family: Open Verdana, Geneva, Tahoma, sans-serif;
  color: black;
}

a {
  text-decoration: none;
  color: #58008e;
}
li {
  list-style-type: none;
}

.container-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  /* background-color: #9eb9bb; */

  padding: 1rem;
  font-weight: bolder;
  font-size: larger;
}

@keyframes hamburger {
  from {
    opacity: 0%;
  }
  to {
    opacity: 80%;
  }
}

.container-navbar-side {
  display: flex;
  flex-direction: column;
  background-color: #c7c8c3;
  margin-bottom: 5%;
  animation: hamburger 1s;
}
.container-navbar-side img {
  display: none;
}

.hamburger-icon {
  display: none;
}

.container-navbar li {
  display: inline-block;
  padding: 0.5rem;
}
.container-navbar li:hover {
  opacity: 80%;
  background-color: #c7c8c3;
}
.container-navbar ul {
  display: flex;
  /* padding: 5%; */
  column-gap: 1.5rem;
  color: black;
  /* background-color: crimson; */
}

.about-me {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr;
  background-color: #eaeaea;

  grid-column-gap: 10vw;
}
.about-me-image {
  width: 30vw;
  height: auto;
  border-radius: 5%;
}

.hero img {
  width: 100vw;
}
/* .hero h1 {
  font-size: 3rem;
  color: white;
  text-align: center;
  position: absolute;
  top: -10vh;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
  margin: auto;
} */
.hero {
  /* display: flex; */
  background-image: url("./assets/bg-image-28.jpg");
  height: 45vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  place-items: center;
  color: #7dd4ff;
}

.hero h1 {
  font-size: 3rem;
  text-align: center;
  position: absolute;
  top: 0vh;
  bottom: 30vh;
  left: 0vw;
  right: 35vw;
  height: fit-content;
  margin: auto;
}
.hero-header2 {
  padding-top: 5vh;
  padding-right: 5vw;
  font-style: italic;
}

footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-left: 3vw;
  padding-right: 3vw;
  margin: 5%;
  justify-content: center;
  align-items: center;
  /* background-color: aqua; */
}

.footer-social-icons {
  display: flex;
  column-gap: 1vw;
}
footer img {
  width: 10vw;
}
.footer-wesite-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.certificates {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin: 5%;
  padding-left: 3vw;
  padding-right: 3vw;
  grid-gap: 1vw;
  justify-items: center;
}

.certificates img {
  width: 40vw;
  /* position: center; */
}

.certificates article {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  background-color: #eaeaea;
  /* justify-content: center;
  align-items: center; */
  /* justify-content: end; */
  padding: 1vw;
  justify-items: center;
}
.two-grid {
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.certificates img:hover {
  /* opacity: 80%; */
  transform: scale(1.2);
  transition: 1s;
  /* border-radius: 10%; */
  box-shadow: 2px 2px 4px #000000;
  /* animation: image-zoomer, 2s; */
}

.contactme-form {
  display: grid;
  background-color: #eaeaea;
  margin-left: 25vw;
  margin-right: 25vw;
  border-radius: 10%;
}
.contactme {
  display: grid;
  grid-template-columns: 30vw;
  grid-gap: 1vh;
  justify-content: center;
  padding-bottom: 5vh;
}

.contactme label {
  font-size: small;
  font-style: italic;
}
.contactme input {
  height: 3vh;
  font-size: large;
}
.contactme textarea {
  font-size: large;
}
.contactme #submit-button {
  font-size: large;
  color: #ffffff;
  background-color: #58008e;
  width: 20vw;
}
.contactme #submit-button:hover {
  opacity: 80%;
}
.contactme #submit-button:active {
  background-color: #7dd4ff;
  color: #58008e;
}

.certificates-page-specialization {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 5%;
  padding-left: 3vw;
  padding-right: 3vw;
  grid-gap: 1vw;
  justify-items: center;
}
.certificates-page-specialization img {
  width: 40vw;
}

.certificates-page-specialization article {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  background-color: #eaeaea;
  /* justify-content: center;
  align-items: center; */
  /* justify-content: end; */
  padding: 1vw;
  justify-items: center;
}

.center-h1 {
  text-align: center;
}

/* .other-certificates {
  display: flex;
  flex-direction: row;
  column-gap: 2vw;
  padding: 1vw;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
} */
.other-certificates {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 2vw;
  padding: 1vw;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}
.other-certificates article {
  display: grid;
  grid-template-columns: auto auto;
  background-color: #eaeaea;
  padding: 1vw;
  margin: 1vw;
  align-items: center;
}
.other-certificates img {
  width: 5vw;
}

.other-certificates a {
  color: #000000;
}
.other-certificates a:hover {
  opacity: 80%;
  box-shadow: 3px 3px 5px 3px #58008e;
  z-index: 1.5;
}
.other-certificates a:active {
  box-shadow: 5px 10px 20px #58008e inset;
  transform: scale(0.9);
  opacity: 100%;
}

.education {
  display: grid;
  grid-template-columns: 40% 60%;
}
.education img {
  width: 15vw;
}

.alignment {
  margin: 5%;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
}
.line-grids {
  display: grid;
  grid-template-columns: 1fr;
  background-color: #eaeaea;
}

.skills {
  display: flex;
  column-gap: 1vw;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* background-color: #eaeaea; */
}
.skills h3 {
  background-color: #eaeaea;
  padding: 1vw;
  /* animation: fade-intro 3s; */
}

@keyframes fade-intro {
  from {
    transform: scale(0.2);
    /* transform: rotate(-30deg); */
    opacity: 0%;
  }
  to {
    opacity: 100%;
    transform: scale(1);
  }
}

.video {
  display: grid;
  justify-content: center;
  width: 30vw;
}

.header-navigator {
  opacity: 30%;
  color: darkslateblue;
}

.portfolio {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  grid-column-gap: 5%;
}
.portfolio img {
  width: 100%;
  height: auto;
}
.portfolio div {
  position: relative;
}
.portfolio div:hover {
  transform: scale(1.1);
  /* zoom: 1.5; */
  transition: 0.5s;
}

.portfolio h2 {
  position: absolute;
  top: 30%;
  left: 10%;
  right: 10%;
  color: white;
  text-align: center;
  background-color: #58008e;
  opacity: 70%;
}

.projectHighlights {
  display: grid;
  /* flex-direction: row; */
  grid-template-columns: auto auto auto;
  grid-template-rows: 1fr;
  margin: 5vw;
  column-gap: 1vw;
  position: relative;
  /* display: inline-block; */
}
.projectHighlights img {
  display: block;
  width: 30vw;
  height: auto;
}
.projectHighlights .card {
  position: relative;
}
.projectHighlights h2 {
  /* display: block; */
  position: absolute;
  top: 30%;
  left: 20%;
  right: 20%;
  padding: 5%;
  color: white;
  text-align: center;
  background-color: #58008e;
  opacity: 70%;
}

@media only screen and (max-width: 700px) {
  body {
    font-size: smaller;
  }
  .container-navbar {
    display: none;
  }
  .hamburger-icon {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .hamburger-icon-none {
    display: none;
  }
  .hamburger-icon img {
    width: 50px;
  }
  .contactme-form {
    display: grid;
    background-color: #eaeaea;
    margin-left: 5vw;
    margin-right: 5vw;
    border-radius: 5%;
  }
  .contactme {
    display: grid;
    grid-template-columns: 70vw;
    grid-gap: 1vh;
    justify-content: center;
    padding-bottom: 5vh;
  }
  .hero {
    /* display: flex; */
    background-image: url("./assets/bg-image-28.jpg");
    height: 25vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: grid;
    place-items: center;
    color: #7dd4ff;
  }
  .hero h1 {
    font-size: 2rem;
    text-align: center;

    position: static;
  }
  .hero-header2 {
    padding-top: 1vh;
    padding-right: 5vw;
    font-style: italic;
    position: static;
  }

  .video {
    display: grid;
    justify-content: center;
  }
  h2 {
    font-size: medium;
  }
}
